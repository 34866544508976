import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero/Hero"

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <Hero img={data.indexBanner.childImageSharp.fluid} error />
  </Layout>
)

export default NotFoundPage
export const query = graphql`
  query {
    indexBanner: file(relativePath: { eq: "colorbar.png" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
